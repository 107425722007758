// mixin.js
import AMapLoader from '@amap/amap-jsapi-loader';

export const lazyMixin = {
  data() {
    return {
      isVisible: false // 用于控制 iframe 是否显示
    };
  },
  mounted() {
    this.$nextTick(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            this.isVisible = true;
            this.lazyLoad();
          } else {
            // 当 iframe 离开视口时销毁它
            this.isVisible = false;
            this.lazyOut();
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(this.$refs.container); // 观察外层容器
    });
  },
};

