<script >
// import 'vue-video-player/src/custom-theme.css'
import hlsjs from 'hls.js'

export default {
  components: {},

  props: {
    vodeoList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      videoUrl: 'https://v7.tlkqc.com/wjv7/202408/08/448Gh0JTJv79/video/1000k_720/hls/index.m3u8',
    }
  },

  mounted() {
    this.videoPlay()
  },

  methods: {
    videoPlay() {
      this.video = this.$refs.videoAug //定义挂载
      if (hlsjs.isSupported()) {
        this.hlsjs = new hlsjs()
        this.hlsjs.loadSource(this.videoUrl) //加载播放地址
        this.hlsjs.attachMedia(this.video) //解析到video标签上
        this.hlsjs.on(hlsjs.Events.MANIFEST_PARSED, () => {
          this.video.play() //开始播放
          console.log('加载成功')
        })
        this.hlsjs.on(hlsjs.Events.ERROR, (event, data) => {
          // 监听出错事件
          console.log('加载失败')
        })
      } else {
        console.log('不支持的格式')
        return
      }
    },
  },
}
</script>

<template>
  <div class="videoMain">
    <div class="video-grid" >
      <div class="video-item" v-for="(item, index) in vodeoList" :key="index" >
        <video width="100%" height="100%" muted controls ref="videoAug">
          <source type="video/mp4" :src="item.url" />
        </video>
      </div>
    </div>
  </div>
</template>



<style scoped lang="scss">
.videoMain {
  height: 70vh; /* 根据需要调整高度 */
  width: 100%;
  overflow-y: scroll;
}
.video-grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr); /* 创建两列 */
  gap: 10px; /* 设置网格项之间的间隙 */
}

.video-item {
  /* 确保视频在其容器内保持其宽高比 */
  position: relative;
  width: 100%; /* 宽度填满列 */
  padding-top: 56.25%; /* 假设视频宽高比为16:9，这里用百分比设置高度 */
  overflow: hidden;
}
.video-item video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 保持视频的宽高比，同时填满容器 */
}

.video {
  // height: calc(70vh - 260px); /* 根据需要调整高度 */
  padding: 2px;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #014e5e #ffffff00; /* For Firefox */

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #014e5e;
    border-radius: 2px;
    border: 1px solid #ffffff; /* Adjust the border color if needed */
  }
}
</style>
