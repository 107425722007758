<template>
  <div class="block" :style="{'width': width,'height':'96%',marginRight:'45px'}"   >

    <div class="block-head" @mouseenter="eidtBtnDisplay" @mouseleave="handleMouseLeave">
      <img src="../../../../assets/images/data/icon1.png" />
      <p style="margin-left: 8px">{{name}}</p>
      <div class="topMenu" v-show="topMenuType" v-if="bigPictureEdit">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <el-button>编辑</el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item  @click.native="addTitle">标题</el-dropdown-item>
              <el-dropdown-item  @click.native="stypeFunc">宽度</el-dropdown-item>

              <el-dropdown-item  @click.native="delBlockItemFunc">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
     </div>
    <div class="block-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import graphicList from "../graphicList/graphicList.vue"
import Tabs from "@/views/data/components/tabs/tabs.vue";
import webView from "@/views/data/components/webView/webView.vue";
export default {
  components: {
    Tabs, graphicList, webView
  },
  props: {
    cardIndexId:{
      type:Object,
      default:()=>{}
    },
    name: {
      type: String,
      default:"新闻板块"
    },
    width:{
      type:String,
      default:"400px"
    }
  },
  data() {
    return {
      topMenuType:false,
      centerDialogVisible:false,
      title:"",
      widthNum:"",
    };
  },

  computed: {
      currentDepartment() {
      return this.$store.getters["schema/schema"].value

    },
    bigPictureEdit(){
      return this.$store.getters["bigPictureEditType"]
    }
  },
  methods: {
    selectTab(index) {
    },
    handleMouseLeave:function(){

      this.topMenuType = !this.topMenuType
    },



    /**
     * @Author: YangXin
     * @Description: 删除弹窗确认
     * @Date: 2024-08-15 09:43:55
     * @return {*}
     */
    delBlockItemFunc:function(){
      this.$confirm('此操作将移除卡片以及其中的所有内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = this.currentDepartment[this.cardIndexId.prentId].children
         data.splice(this.cardIndexId.indexBlock, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });


    },

    render(h) {
      return h('div', null, [
        h('div', {
          style: "height:30px;width:100%;margin-bottom:5px;display:flex;",
        }, [
          h('div', {

            style: "width:30%;margin:auto;",
          }, "修改编辑宽度："),
          h('el-slider', {
            style: "height:100%;width:70%;",
            props: {
              value: '0',
              step:"10",
              max:"3840"
            },
            on: {
              input: (value) => {
                this.widthNum = value + "px"
              }
            }
          }),

        ]),

      ])


    },
    /**
     * @Author: YangXin
     * @Description: 样式修改
     * @Date: 2024-08-10 20:47:51
     * @return {*}
     */
    stypeFunc:function(){
      const h = this.$createElement;
      this.$msgbox({
        title: '边框宽度修改',
        message: this.render(h),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {
        let data = this.currentDepartment[this.cardIndexId.prentId].children[this.cardIndexId.indexBlock]
          data.width = this.widthNum
        this.$message({
          type: 'info',
          message: "修改成功"
        });
      });

    },
    /**
     * @Author: YangXin
     * @Description: 添加标题
     * @Date: 2024-08-09 18:55:11
     * @return {*}
     */
    addTitle:function(){
      let data = this.currentDepartment[this.cardIndexId.prentId].children[this.cardIndexId.indexBlock]

      this.$prompt('请输入名称', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:data.name
        }).then(({ value }) => {
          data.name = value
          this.$message({
            type: 'success',
            message: '修改成功'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });


      // this.title = ""
      // this.$parent.$parent.$parent.$parent.$parent.$refs.editor.$data.titleDjalogType = true
      // this.$root.titleEditIndex = this.cardIndexId
      // this.$parent.$parent.$parent.$parent.$parent.$refs.titleDialogRef.editTitleFunc(this.cardIndexId)
    },

    eidtBtnDisplay:function(){


      this.$parent.$parent.$parent.$parent.$refs.cardParentRef[this.cardIndexId.prentId].editType = false
      this.topMenuType = true
    }

  }
}

</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  margin: 0 !important;
  margin-left:40px !important;
  margin-top:20px !important;
  z-index: 99999;

}
.block-head{
  background-image: url("~@/assets/images/data/block-head-bg.png");
  background-repeat: no-repeat;
  opacity: 0.7;
  height: 29px;
  width: 80%;
  margin-top: 10px;
  margin-left: 24px;
  padding-left: 5px;

  display: flex;
  align-items: center;

  & > image{
    width: 40px;
    height: 40px;
  }

  & > p{
    background: linear-gradient(0deg, #66FFFF 0%, #C3F8B3 100%);
    -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
    -webkit-text-fill-color: transparent; /*给文字设置成透明*/
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
  }
}
.block-body{
  margin: 16px;
  //background-color: #1e8ce4;
  height: 75vh;
}






.block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: polygon(
      31px 0, calc(100% - 31px) 0, 100% 31px,
      100% calc(100% - 31px), calc(100% - 31px) 100%,
      31px 100%, 0 calc(100% - 31px), 0 31px
  );
  background:linear-gradient(180deg, #004146 0%, #000A25 7%, #000a25e0 50%);

  z-index: -1; /* 确保伪元素在内容下方 */
  border: 1px solid #23FFF988;
}

.block {
  //background: #000A25;
  position: relative; /* 确保伪元素定位正确 */
  background-color: #23FFF988;
  clip-path: polygon(30px 0, calc(100% - 30px) 0, 100% 27px,
    100% calc(100% - 30px), calc(100% - 30px) 100%,
    30px 100%,
    0 calc(100% - 30px),
    0 20px
  );
  z-index: 1;
}

</style>
