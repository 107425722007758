<template>
  <div class="block" style="width: 400px;">
    <div class="block-head">
      <img ref="imgIcon" src="../../../../assets/images/data/icon1.png"/>
      <tabs :data="tabsList" @tab-selected="handleTabSelected"></tabs>
    </div>
    <div class="block-body">

      <keep-alive>
        <component :is="tabsList[selectedTab].type"
                   :attribute="tabsList[selectedTab].attribute"
                   :data="tabsList[selectedTab].data"
                   :mapping="tabsList[selectedTab].mapping"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import Tabs from "../tabs/tabs.vue";
import graphicList from "../graphicList/graphicList.vue"
import webView from "../webView/webView.vue"
import  gzjb from "../../mock/home/gzjb.json"
export default {
  components: {
    Tabs, graphicList, webView
  },
  props: {
    data: {
      type: Array,
    }
  },
  data() {
    return {
      currentComponent: "",
      tabsList: [
        {
          id: 2, name: "工作简报 专报 信息",
          type: "graphicList",
          attribute: {
            src: "http://nm.ld.vc14.com/#/pages/index/index",
            style: "width:100%;height:100%"
          },
          data:gzjb.data.records,
          mapping:{title:'title',src:'images',date:'created_at',source:'author'}

        },
        // {
        //   id: 1,
        //   name: "工作动态",
        //   type: "webView",
        //   attribute: {
        //     src: "http://nm.ld.vc14.com/#/pages/index/index",
        //     style: "width:100%;height:100%"
        //   }
        // },
        // {id: 3, name: "成绩成效", url: "https://example.com"},
        // {id: 4, name: "制度法规", url: "https://example.com"},
        // {id: 5, name: "其他", url: "https://example.com"},
      ],
      selectedTab: 0
    };
  },
  methods: {
    handleTabSelected(index) {
      console.log(index)
      this.selectedTab = index;
    }
  },
  mounted() {
    // const img = this.$refs['imgIcon'];
    // debugger
    // gsap.to(img, {
    //   scale: 1.2,
    //   duration: 1,
    //   yoyo: true,
    //   repeat: -1,
    //   ease: "power1.inOut"
    // });
    // gsap.to(img, {
    //   rotation: 360,
    //   duration: 2,
    //   repeat: -1,
    //   ease: "none"
    // });
  }
}
</script>

<style lang="scss" scoped>

.block-head {
  background-image: url("~@/assets/images/data/block-head-bg.png");
  background-repeat: no-repeat;
  opacity: 0.7;
  height: 29px;
  width: 80%;
  margin-top: 10px;
  margin-left: 24px;
  padding-left: 5px;

  display: flex;
  align-items: center;

  & > image {
    width: 40px;
    height: 40px;
  }

  & > p {
    background: linear-gradient(0deg, #66FFFF 0%, #C3F8B3 100%);
    -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
    -webkit-text-fill-color: transparent; /*给文字设置成透明*/
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
  }
}

.block-body {
  margin: 16px;
  //background-color: #1e8ce4;
  height: calc(100% - 80px);
}


.block::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: polygon(
      31px 0, calc(100% - 31px) 0, 100% 31px,
      100% calc(100% - 31px), calc(100% - 31px) 100%,
      31px 100%, 0 calc(100% - 31px), 0 31px
  );
  background: linear-gradient(180deg, #004146 0%, #000A25 7%, #000a25e0 50%);

  z-index: -1; /* 确保伪元素在内容下方 */
  border: 1px solid #23FFF988;
}

.block {
  //background: #000A25;
  position: relative; /* 确保伪元素定位正确 */
  background-color: #23FFF988;
  clip-path: polygon(30px 0, calc(100% - 30px) 0, 100% 30px,
    100% calc(100% - 30px), calc(100% - 30px) 100%,
    30px 100%,
    0 calc(100% - 30px),
    0 20px
  );
  z-index: 1;
}

</style>
