<template>
  <div style="display: flex; justify-content: center; align-items: center;"  ref="container">
    <div ref="map" class="map" v-if="isVisible"></div>
    <el-dialog
      title="市场主体企业详情"
      :visible.sync="scztInfoVisible"
      :modal="false"
      :before-close="handleClose"
      custom-class="custom-dialog"
      v-if="this.selectInfo"
      :append-to-body="true" :lock-scroll="false"
    >
      <!-- 自定义标题样式 -->
      <template #title>
        <span class="dialog-title">市场主体企业详情</span>
      </template>
      <div class="dialog-content">
      <el-table :data="details" style="width: 100%" stripe>
        <el-table-column prop="label" label="属性" width="180">
        </el-table-column>
        <el-table-column prop="value" label="值">
          <template #default="{ row }">
            <div v-if="row.label === '主体图片'">
              <div class="image-gallery">
                <img v-for="(image, index) in bodyImages" :key="index" :src="image" style="height: 300px;" />
              </div>
            </div>
            <span v-else>{{ row.value }}</span>
          </template>
        </el-table-column>
      </el-table>

    </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMixin } from './map.mixin.js';
import { lazyMixin} from "../base/lazyload.mixin"
import { data } from 'jquery';
import AMapLoader from '@amap/amap-jsapi-loader';
import { ElButton, ElDialog } from 'element-ui';



export default {
  name: "marker-cluster",
  mixins: [mapMixin,lazyMixin],
  data() {
    return {
      mapData: '',
      markerList: [],
      scztInfoVisible: false,
      selectInfo: null,
      bodyImages: [], // 存储图片 URL 的数组
    };
  },
  props: {
    data: {
      type: Array,
    },
  },
  beforeUpdate() {
    console.log('Before update:', this.data);
  },
  updated() {
    console.log('Updated:', this.data);
  },
  created() {
    let that = this;
    this.$watch('data', (newVal, oldVal) => {
      // console.log('Data changed:', newVal, 'from', oldVal);
      setTimeout(() => {
        // console.log(this.data)
        this.setData()
      }, 2000);
    }, { immediate: true, deep: true });
  },
  mounted() {
    // setTimeout(() => {
    //   // console.log(this.data)
    //   this.setData()
    // }, 5000);
    // this.setData()
  },

  methods: {



    setData: function () {
      /**
        * LabelsLayer
        */

      // 创建 AMap.LabelsLayer 图层
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        collision: false
      });

      // 将图层添加到地图
      this.map.add(layer);

      let self = this;

      var icon = {
        type: 'image',
        image: require('../../../../assets/images/logo.png'),
        size: [7, 10],
        anchor: 'bottom-center',
      };

      this.data.map((item, index) => {

        // let tempItem = {
        //   lnglat: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
        //   data: item,
        // }
        const labelMarker = new AMap.LabelMarker({
          name: "标注", //此属性非绘制文字内容，仅为标识使用
          position: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
          zIndex: 1000,
          rank: 1, //避让优先级
          icon: icon, //标注图标，将 icon 对象传给 icon 属性
          // text: text, //标注文本，将 text 对象传给 text 属性
        });


        labelMarker.on('click', function (e) {
          // console.log(e)
          self.showInfoDialog(item)
          // self.openInfo(item,labelMarker.getPosition())

        });
        this.markerList.push(labelMarker)



      })


      // 一次性将海量点添加到图层
      layer.add(this.markerList);

    },

    showInfoDialog(e) {
      console.log(e)
      this.scztInfoVisible = true;
      this.selectInfo = e
    },

    openInfo(obj, lnglat) {
      console.log(obj)
      //构建信息窗体中显示的内容
     // debugger
      var info = [];
      info.push("<div class='input-card content-window-card'>" +
        "<div><h3>" + obj.bodyName + "</h3></div> ");
      info.push("<div style=\"padding:7px 0px 0px 0px;\"><h4>负责人：" + obj.contactName + "</h4>");
      info.push("<p class='input-item'>主营 : " + (obj.farmingProduct || '') + "   </p>");
      info.push("<p class='input-item'>电话 : " + (obj.corpPhone || '') + "   地区 : " + obj.city + "</p>");
      info.push("<p class='input-item'>地址 :" + (obj.address || '') + "</p></div></div>");
      info.push("<img src='" + obj.bodyCertificateImage + "' height='300' />");



      let infoWindow = new AMap.InfoWindow({
        content: info.join("")  //使用默认信息窗体框样式，显示信息内容
      });

      infoWindow.open(this.map, lnglat);
    },

    loadData(that) {


      var style = {
        url: require('../../../../assets/images/logo.png'), //图标地址

        size: new AMap.Size(11, 11), //图标大小
        anchor: new AMap.Pixel(5, 5), //图标显示位置偏移量，基准点为图标左上角
      };

      that.mapData.data.map((item, index) => {

        let tempItem = {
          lnglat: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
          data: item,
        }

        that.markerList.push(tempItem)


      })

      console.log(that.markerList)

      var massMarks = new AMap.MassMarks(that.markerList, {
        zIndex: 30, //海量点图层叠加的顺序
        zooms: [3, 19], //在指定地图缩放级别范围内展示海量点图层
        style: style, //设置样式对象
      });
      massMarks.on('click', e => {
        that.openInfo(e, e.data.lnglat)
      })

      massMarks.setMap(that.map);

      /**
       * LabelsLayer
       */

      // 创建 AMap.LabelsLayer 图层
      // var layer = new AMap.LabelsLayer({
      //   zooms: [3, 20],
      //   zIndex: 1000,
      //   collision: false
      // });

      // // 将图层添加到地图
      // that.map.add(layer);

      // var icon = {
      //   type: 'image',
      //   image: 'https://nmtwfcstorage.yunshangwulian.vip/dian1.png',
      //   size: [7, 10],
      //   anchor: 'bottom-center',
      // };

      // that.mapData.data.map((item, index) => {

      //   // let tempItem = {
      //   //   lnglat: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
      //   //   data: item,
      //   // }
      //   const labelMarker = new AMap.LabelMarker({
      //     name: "标注", //此属性非绘制文字内容，仅为标识使用
      //     position: [parseFloat(item.subjectLongitude), parseFloat(item.subjectLatitude)],
      //     zIndex: 1000,
      //     rank: 1, //避让优先级
      //     icon: icon, //标注图标，将 icon 对象传给 icon 属性
      //     // text: text, //标注文本，将 text 对象传给 text 属性
      //   });

      //   that.markerList.push(tempItem)


      // })


      // // 一次性将海量点添加到图层
      // layer.add(that.markerList);

    },

    /**所属行业 */
    setCprz(num) {
      if (num == '1') {
        return '绿色'
      } else if (num == '2') {
        return '有机'
      } else if (num == '3') {
        return '地理标识'
      } else if (num == '4') {
        return '区域共享品牌'
      } else if (num == '5') {
        return '名特优新'
      }

    },
    setSshy(num) {
      if (num == '1') {
        return '种植业'
      } else if (num == '2') {
        return '畜牧业'
      } else if (num == '3') {
        return '水产业'
      } else if (num == '4') {
        return '其他行业'
      }
    },
    setStatus(num) {
      if (num == '0') {
        return '正常'
      } else if (num == '1') {
        return '注销'
      } else if (num == '3') {
        return '停产'
      }
    },
    setBodyAttr(num) {
      if (num == '1') {
        return '生产主体'
      } else if (num == '2') {
        return '屠宰场'
      } else if (num == '3') {
        return '生鲜乳企业'
      } else if (num == '4') {
        return '投入品经营主体'
      } else if (num == '5') {
        return '投入品经营主体'
      }
    },
    setqylx(num) {
      if (num == '1') {
        return '企业'
      } else if (num == '2') {
        return '合作社'
      } else if (num == '3') {
        return '家庭农场'
      } else if (num == '4') {
        return '个体小散户'
      } else if (num == '5') {
        return '种植大户'
      } else if (num == '6') {
        return '其它'
      }
    },
  },
  computed: {

    details() {
      return [
        { label: '主体名称', value: this.selectInfo.bodyName },
        { label: '产品认证', value: this.setCprz(this.selectInfo.approve) },
        { label: '行政区划', value: this.selectInfo.area },
        { label: '详细地址', value: this.selectInfo.address },
        { label: '主体所属行业', value: this.setSshy(this.selectInfo.business) },
        { label: '营业期限', value: this.selectInfo.businessTerm },
        { label: '主体状态', value: this.setStatus(this.selectInfo.cancel) },
        { label: '主体属性', value: this.setBodyAttr(this.selectInfo.subjectAttribute) },
        { label: '企业类型', value: this.setqylx(this.selectInfo.enterpriseType) },
        { label: '是否龙头企业', value: this.selectInfo.isLeadingEnterprise === '0' ? '否' : '是' },
        { label: '规模', value: this.selectInfo.farmingScale },
        { label: '组织机构代码证', value: this.selectInfo.orgCode },
        { label: '生产产品', value: this.selectInfo.farmingProduct },
        { label: '联系人', value: this.selectInfo.contactName },
        { label: '联系人电话', value: this.selectInfo.contactPhone },
        { label: '法人', value: this.selectInfo.corpName },
        { label: '法人电话', value: this.selectInfo.corpPhone },
        { label: '主体图片', value: '' }, // 添加图片展示行
      ];
    },

    splitBodyImages() {
    if (this.selectInfo && this.selectInfo.bodyImage) {
      const images = this.selectInfo.bodyImage.split(',');
      const host = 'https://www.nmgnxza.cn:63';

      // 处理第一个 URL
      let firstImageUrl = images[0];
      if (!firstImageUrl.startsWith('http')) {
        firstImageUrl = `${host}/${firstImageUrl}`;
      }

      // 处理剩余的 URL
      const otherImages = images.slice(1).map(imageUrl => `${host}/${imageUrl}`);

      return [firstImageUrl, ...otherImages];
    }
    return [];
  },
  },
  watch: {

    splitBodyImages(newVal) {
      this.bodyImages = newVal;
    },
  },

};
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: calc(100vh - 260px);
  color: #000A25 !important;
}

.input-card {
  color: #000A25;
}
.custom-dialog {
  width: 80%;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.dialog-title {

  font-size: 1.2em;
  color: #333;
}

.dialog-content {
  max-height: 500px; /* 可根据实际情况调整 */
  overflow-y: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.dialog-item {
  margin-bottom: 10px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
  margin-top: 20px;
}

.image-gallery img {
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.dialog-footer {
  text-align: right;
}

/* 为按钮添加样式 */
.el-button {
  margin-top: 10px;
}


</style>
